
import { Component, Vue } from 'vue-property-decorator'
import { CarIllegalInfo } from '@/types/carManage'

@Component({
  name: 'detailIllegal'
})
export default class extends Vue {
  private info: CarIllegalInfo = {
    projectId: '',
    vehicleId: '',
    vehicleType: '',
    violationTime: '',
    violationAddress: '',
    handleContent: '',
    brand: '',
    model: '',
    vehicleTypeName: ''
  }

  created () {
    const violationId = this.$route.params.id
    violationId && this.getDetail(violationId)
  }

  getDetail (violationId: string) {
    this.$axios.get(this.$apis.carManage.selectYhVehicleViolationByViolationId, { violationId }).then((res) => {
      this.info = res
    })
  }
}
